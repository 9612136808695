import '../empresas.css';
import './admin.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header.js';

const UsuariaDetalle = () => {
    const [usuaria, setUsuaria] = useState(null);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const jwtToken = localStorage.getItem('accessToken');
    const location = useLocation();
    const navigate = useNavigate();
    const { numeroCandidata, resultados } = location.state || {}; // Datos desde la navegación con estado
    const [currentIndex, setCurrentIndex] = useState(numeroCandidata ? numeroCandidata - 1 : null);
    const [cvLink, setCvLink] = useState(null); 

    const fetchUsuaria = async (usuariaId) => {
        try {
            const response = await axios.get(
                `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${usuariaId}`,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setUsuaria(response.data);
        } catch (error) {
            console.error('Error al cargar los datos de la usuaria:', error);
        }
    };

    const fetchCv = async (usuariaId) => {
        try {
            const response = await axios.get(
                `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/users/usuaria/${usuariaId}/cv`,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setCvLink(response.data.cvlink);
        } catch (error) {
            console.error('Error al obtener el CV:', error);
            setCvLink(null); // Asegúrate de que sea null si no hay CV
        }
    };

    // Cargar datos al montar el componente o cambiar el índice
    useEffect(() => {
        if (resultados && currentIndex !== null) {
            // Caso con lista y número de candidata
            fetchUsuaria(resultados[currentIndex].usuaria.id);
            fetchCv(resultados[currentIndex].usuaria.id);
        } else if (id) {
            // Caso sin lista ni número de candidata
            fetchUsuaria(id);
            fetchCv(id);
        }
    }, [currentIndex, resultados, id]);

    // Navegación hacia anterior y siguiente en la lista
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (resultados && currentIndex < resultados.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };


    if (error) {
        return (
            <div className="WT1">
                <Header />
                <p>Error al cargar los datos de la usuaria. Por favor, inténtalo de nuevo más tarde.</p>
            </div>
        );
    }

    if (!usuaria) {
        return (
            <div className="WT1">
                <Header />
                <p>Cargando datos de la usuaria...</p>
            </div>
        );
    }
    return (
        <div className="WT1">
            <Header />
            <div className="containerMatch1">
                <p className="boldMiPerfil">
                    {usuaria.nombre} {usuaria.apellido}
                    {numeroCandidata && ` - Candidata #${currentIndex + 1}`}
                    {cvLink && (
                        <button
                            style={{ marginLeft: '10px', padding: '5px 10px', cursor: 'pointer' }}
                            onClick={() => window.open(cvLink, '_blank')}
                        >
                            Descargar CV
                        </button>
                    )}
                </p>
                <div className="subcontainerMatchimg">
                    <img src={wot25} alt="Usuaria" className="subcontainerMatchimg" />
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Rut: {usuaria.rut || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Mail: {usuaria.mail || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Celular: {usuaria.celular || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Profesiones: {usuaria.profesion && usuaria.profesion.length > 0
                            ? usuaria.profesion.map((p) => p.nombre).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Universidad: {usuaria.universidad || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Postgrado: {usuaria.postgrado || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Cargo: {usuaria.cargo?.cargo || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Industria: {usuaria.industria?.nombre_industria || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Empresa: {usuaria.empresa_actual || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Cargo Adicional: {usuaria.aditionalCargo?.cargo || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Industria Adicional: {usuaria.aditionalIndustria?.nombre_industria || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Empresa Adicional: {usuaria.empresa_adicional || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Años Experiencia:{' '}
                        {usuaria.aniosExperiencia
                            ? `${usuaria.aniosExperiencia.rango_experiencia_desde} - ${usuaria.aniosExperiencia.rango_experiencia_hasta}`
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Areas Experiencia: {usuaria.areas && usuaria.areas.length > 0
                            ? usuaria.areas.map((p) => p.nombre).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Subareas Experiencia: {usuaria.subareas && usuaria.subareas.length > 0
                            ? usuaria.subareas.map((p) => p.nombre).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Industrias experiencia: {usuaria.industrias && usuaria.industrias.length > 0
                            ? usuaria.industrias.map((p) => p.nombre_industria).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Competencias: {usuaria.competencia && usuaria.competencia.length > 0
                            ? usuaria.competencia.map((p) => p.competencia).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Disponibilidad: {usuaria.disponibilidad && usuaria.disponibilidad.length > 0
                            ? usuaria.disponibilidad.map((p) => p.disponibilidad).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Experiencia en directorios: 
                        {usuaria.experienciaDirectorios !== undefined 
                            ? (usuaria.experienciaDirectorios ? 'Sí' : 'No') 
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Formación en alta dirección: 
                        {usuaria.altaDireccion !== undefined 
                            ? (usuaria.altaDireccion ? 'Sí' : 'No') 
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Intereses: {usuaria.intereses || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Brief: {usuaria.brief || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">LinkedIn: {usuaria.redesSociales || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Personalidad: {usuaria.personalidad?.personalidad || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Idiomas: {usuaria.idiomas && usuaria.idiomas.length > 0
                            ? usuaria.idiomas.map((p) => p.nombre).join(", ")
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Factor: {usuaria.factor || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">Pueblo Originario: {usuaria.nombrePuebloOriginario || 'No disponible'}</h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Región Compromiso: {usuaria.regionCompromiso?.nombre || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        País Domicilio: {usuaria.paisDomicilio?.pais || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Región Domicilio: {usuaria.regionActualDomicilio?.nombre || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Posibilidad de cambiarse de región:{' '}
                        {usuaria.posibilidadCambiarseRegion?.posibilidad || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Disposición Viaje:  
                        {usuaria.disposicion_viajar !== undefined 
                            ? (usuaria.disposicion_viajar ? 'Sí' : 'No') 
                            : 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Modalidad: {usuaria.tipoModalidad?.tipoModalidad || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Jornada: {usuaria.tipoJornada?.tipoJornada || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Herramientas adicionales: {usuaria.herramientas || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Expectativa de renta: {usuaria.expectativaRenta || 'No disponible'}
                    </h>
                </div>
                <div className="subcontainerMatch">
                    <h className="normalMiPerfil">
                        Cómo conoció WoT: {usuaria.conocioWOT?.conocio || 'No disponible'}
                    </h>
                </div>
                {resultados ? (
                    <div>
                        <button onClick={handlePrev} disabled={currentIndex === 0}>
                            Anterior
                        </button>
                        <button onClick={handleNext} disabled={currentIndex === resultados.length - 1}>
                            Siguiente
                        </button>
                    </div>
                ) : (
                    <p>Navegación no disponible</p>
                )}
            </div>
            <img src={wot24} alt="circulo" className="wot24Empresas" />
        </div>
    );
};

export default UsuariaDetalle;