import '../empresas.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import wot24 from '../iconos/wot24.png';

const logout = () => {
  localStorage.removeItem('accessToken');
  window.location.reload();
};

const DetallesResultadoDesbloqueado = ({ busqueda, usuaria, volverAtras }) => {
  return (
    <div className="containerMiPerfilMatch">
      <h2>Detalles de la Búsqueda</h2>
      <p>Nombre de la búsqueda: {busqueda?.nombre || 'No especificado'}</p>
      <p>Empresa de la búsqueda: {busqueda?.empresa?.nombre || 'No especificado'}</p>
      <p>Cargo de la búsqueda: {busqueda?.cargo?.nombre || 'No especificado'}</p>
      <p>Jornada de la búsqueda: {busqueda?.jornada?.nombre || 'No especificado'}</p>
      <p>Región de la búsqueda: {busqueda?.region?.nombre || 'No especificado'}</p>
      <p>Modalidad de la búsqueda: {busqueda?.modalidad?.nombre || 'No especificado'}</p>
      <p>Profesiones de la búsqueda: {busqueda?.profesiones?.map((profesion) => profesion.nombre).join(', ') || 'No especificado'}</p>
      <p>Competencias de la búsqueda: {busqueda?.competencias?.map((competencia) => competencia.nombre).join(', ') || 'No especificado'}</p>
      <p>Industrias de la búsqueda: {busqueda?.industrias?.map((industria) => industria.nombre).join(', ') || 'No especificado'}</p>
      <p>Idiomas de la búsqueda: {busqueda?.idiomas?.map((idioma) => idioma.nombre).join(', ') || 'No especificado'}</p>
      <p>Áreas de la búsqueda: {busqueda?.areas?.map((area) => area.nombre).join(', ') || 'No especificado'}</p>

      <h2>Detalles de la Usuaria</h2>
      <p>Nombre de la usuaria: {usuaria?.nombre || 'No especificado'}</p>
      <p>Apellido de la usuaria: {usuaria?.apellido || 'No especificado'}</p>
      <p>Rut de la usuaria: {usuaria?.rut || 'No especificado'}</p>
      <p>Número de celular de la usuaria: {usuaria?.celular || 'No especificado'}</p>
      <p>Correo electrónico de la usuaria: {usuaria?.mail || 'No especificado'}</p>
      <p>Universidad de la usuaria: {usuaria?.universidad || 'No especificado'}</p>
      <p>Postgrado de la usuaria: {usuaria?.postgrado || 'No especificado'}</p>
      <p>Empresa actual de la usuaria: {usuaria?.empresa_actual || 'No especificado'}</p>

      <button onClick={volverAtras}>Volver atrás</button>
    </div>
  );
};

const ResultadoDesbloqueadoItem = ({ resultadoDesbloqueado, matchData, handleVerDetalle }) => {
  const fechaMatch = resultadoDesbloqueado.createdAt
    ? new Date(resultadoDesbloqueado.createdAt).toLocaleDateString('es-CL')
    : 'No especificado';

  return (
    <tr>
      <td>{resultadoDesbloqueado.empresa.nombre}</td>
      <td>{resultadoDesbloqueado.busqueda.nombre}</td>
      <td>{fechaMatch}</td>
      <td>{resultadoDesbloqueado.usuario?.user_email || 'No disponible'}</td>
      <td>
        <button onClick={() => handleVerDetalle(resultadoDesbloqueado.busqueda.id, matchData?.id_usuaria)}>
          Ver Detalle
        </button>
      </td>
    </tr>
  );
};

const DesbloqueadosEmpresa = () => {
  const jwtToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { id } = useParams();
  const [company, setCompany] = useState([]);
  const [desbloqueados, setDesbloqueados] = useState([]);
  const [matchesData, setMatchesData] = useState({});
  const [detalleVisible, setDetalleVisible] = useState(false);
  const [busqueda, setBusqueda] = useState(null);
  const [usuaria, setUsuaria] = useState([]);

  const handleVerDetalle = async (id_busqueda, usuariaId) => {
    try {
      const [responseBusqueda, responseUsuaria] = await Promise.all([
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/${id_busqueda}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }),
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${usuariaId}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }),
      ]);
      setBusqueda(responseBusqueda.data);
      setUsuaria(responseUsuaria.data);
      setDetalleVisible(true);
    } catch (error) {
      console.error('Error al obtener información de la búsqueda o usuaria:', error);
    }
  };

  const handleVolverAtras = () => {
    setDetalleVisible(false);
    setBusqueda(null);
    setUsuaria([]);
  };

  useEffect(() => {
    if (!jwtToken) {
      navigate('/loginAdmin');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [empresaResponse, desbloqueadosResponse] = await Promise.all([
          axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/${id}`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }),
          axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/desbloqueados/${id}`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          }),
        ]);

        if (empresaResponse.data) {
          setCompany(empresaResponse.data.empresa);
        }

        if (desbloqueadosResponse.data) {
          setDesbloqueados(desbloqueadosResponse.data.reverse());

          const idMatches = desbloqueadosResponse.data.map((resultado) => resultado.id_match);
          const matchResponse = await axios.post(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/match`,
            { ids: idMatches },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );

          const matchesMap = matchResponse.data.reduce((acc, match) => {
            acc[match.id] = match;
            return acc;
          }, {});

          setMatchesData(matchesMap);
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    };

    fetchData();
  }, [id, jwtToken]);

  return (
    <div className="WT1">
      <Header />

      {detalleVisible ? (
        <DetallesResultadoDesbloqueado
          busqueda={busqueda}
          usuaria={usuaria}
          volverAtras={handleVolverAtras}
        />
      ) : (
        <div className="containerMatch1">
          <p className="boldMiPerfil">Resultados Desbloqueados</p>

          <table className="desbloqueados-table">
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Nombre de la Búsqueda</th>
                <th>Fecha del Match</th>
                <th>Mail</th>
                <th>Ver Detalle</th>
              </tr>
            </thead>
            <tbody>
              {desbloqueados.map((resultado, index) => (
                <ResultadoDesbloqueadoItem
                  key={index}
                  resultadoDesbloqueado={resultado}
                  matchData={matchesData[resultado.id_match]}
                  handleVerDetalle={handleVerDetalle}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}

      <img src={wot24} alt="circulo" className="wot24Empresas" />

      <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="lista" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="lista" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="lista" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="lista" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="lista" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="active" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default DesbloqueadosEmpresa;