import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserAgent } from 'amazon-cognito-identity-js';
import { Link } from 'react-router-dom';
import Header from '../componentes/Header';


const logout = () => {
  localStorage.removeItem('accessToken');
  window.location.reload();
};

// Componente EmpresaItem
const EmpresaItem = ({ company }) => {
  const navigate = useNavigate();

  const handleVerDetalle = (id) => {
    navigate(`/empresa/${id}`);
  };

  return (
    <div className="commonContainer">
      <h>{company.nombre}</h>
      <p>{company.rut}</p>
      <button onClick={() => handleVerDetalle(company.id)} className='boton-busquedas'>Ver Detalle</button>
    </div>
  );
};

// Componente BusquedaItem
const BusquedaItem = ({ search }) => {
  const navigate = useNavigate();

  const handleVerDetalle = (id) => {
    navigate(`/matchs-empresa/${id}`);
  };

  const formattedDate = new Date(search.fecha).toLocaleString();
  return (
    <div className="commonContainer">
      <h>{search.nombre}</h>
      <p>Fecha: {formattedDate}</p>
      <button onClick={() => handleVerDetalle(search.id)} className='boton-busquedas'>Ver Detalle</button>
    </div>
  );
};

const ResultadoDesbloqueadoItem = ({ resultadoDesbloqueado, handleVerDetalle }) => {
  console.log("resultadoDesbloqueado", resultadoDesbloqueado)
  const jwtToken = localStorage.getItem('accessToken');
  const id_match = resultadoDesbloqueado.id_match;
  const [matchs, setMatchs] = useState([]);
  const [usuaria, setUsuaria] = useState(null);
  const [busqueda, setBusqueda] = useState(null);

  

  useEffect(() => {
    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/match/${id_match}`, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setMatchs(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id_match, jwtToken]);



  useEffect(() => {
      const idUsuaria = matchs.id_usuaria;

      axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/usuaria/${idUsuaria}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          // Actualizar el estado con la información de la usuaria
          setUsuaria(response.data);
        })
        .catch(error => {
          console.log(error);
        });
    
  }, [jwtToken]);

  const id_usuaria = matchs.id_usuaria;
  const fechaMatch = resultadoDesbloqueado.createdAt
    ? new Date(resultadoDesbloqueado.createdAt).toLocaleDateString('es-CL')
    : 'No especificado';
  return (
    <tr>
      <td>{resultadoDesbloqueado.empresa.nombre}</td>
      <td>{resultadoDesbloqueado.busqueda.nombre}</td>
      <td>{fechaMatch}</td>
      <td>{resultadoDesbloqueado.usuario?.user_email}</td>
      {/* <td>
        <button onClick={() => handleVerDetalle(resultadoDesbloqueado.busqueda.id, id_usuaria)}>Ver Detalle</button>
      </td> */}
    </tr>
  );
};

const MiPerfil = () => {
  const jwtToken = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [companies, setCompanies] = useState([]);
  const [allSearches, setAllSearches] = useState([]);
  const [desbloqueados, setDesbloqueados] = useState([])
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('busquedas');



  useEffect(() => {
    if (!jwtToken) {
      navigate('/loginAdmin');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/get-all`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data;
        if (Array.isArray(data)) {
          console.log(data);
          setCompanies(data.empresas);
        }
        else if (typeof data === 'object') {
          console.log(data.empresas);
          setCompanies(data.empresas);
        }
      })
      .catch(error => console.error(error));

    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda`, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setAllSearches(response.data);
        setLoading(false);
      })
      .catch(error => console.error(error));

      axios
      .get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/desbloqueados', {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setDesbloqueados(response.data.reverse());
      })
      .catch((error) => {
        console.error('Error al obtener resultados desbloqueados', error);
      });
  }, [jwtToken]);

  console.log('allSearches:', allSearches);
  console.log('companies:', companies);
  console.log('desbloqueados', desbloqueados)

  return (
    <div className="WT1">
        <Header />  
        <div className='containerMiPerfil1'>
            <p className='boldMiPerfil'>Perfil Administradora</p>
            <div className="buttonContainer">
              <button onClick={() => setSelectedTab('empresas')}>Empresas Inscritas</button>
              <button onClick={() => setSelectedTab('busquedas')}>Busquedas Realizadas</button>
              <button onClick={() => setSelectedTab('desbloqueados')}>Desbloqueados</button>
            </div>
      </div>
      <div className='containerMiPerfil2'>
        {selectedTab === 'busquedas' && (
          <>
            <p className='boldMiPerfil'>Busquedas Realizadas</p>
            <p className='normalMiPerfil'> Acá se puede ver un resumen de todas las busquedas.</p>
            {allSearches.length > 0 ? (
              allSearches.map((search, index) => (<BusquedaItem key={index} search={search} />
              ))
            ) : (
              <p>No hay búsquedas disponibles.</p>
            )}
          </>
        )}
        {selectedTab === 'empresas' && (
          <>
            <p className='boldMiPerfil'>Empresas Inscritas</p>
            <p className='normalMiPerfil'> Acá se puede ver un resumen de todas las empresas.</p>
            {console.log('COMPANIES', companies)}
            {companies.length > 0 ? (
              companies.map((company, index) => (<EmpresaItem key={index} company={company} />
              ))
            ) : (
              <p>No hay empresas disponibles.</p>
            )}
          </>
        )}
        {selectedTab === 'desbloqueados' && (
          <>
            <p className='boldMiPerfil'>Resultados Desbloqueados</p>
            <p className='normalMiPerfil'> Acá se pueden ver los resultados desbloqueados por las empresas.</p>
          
              <table className="desbloqueados-table">
                <thead>
                  <tr>
                    <th>Empresa</th>
                    <th>Nombre de la Búsqueda</th>
                    <th>Fecha del Match</th>
                    <th>Mail</th>
                    {/* <th>Ver Detalle</th> */}
                  </tr>
                </thead>
                <tbody>
                  {desbloqueados.map((resultado, index) => (
                    <ResultadoDesbloqueadoItem key={index} resultadoDesbloqueado={resultado}  />
                  ))}
                </tbody>
              </table>

          </>
        )}
      </div>
      <div className='rectanguloEmpresas'>
        <p className='boldMiPerfil'>Información General</p>
      </div>
      <img src={wot24} alt='circulo' className='wot24Empresas' />
    </div>
  );
};

export default MiPerfil;