import '../empresas.css';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';

const AgregarUsuario = () => {
  const jwtToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID de la empresa desde los parámetros de la URL
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [cargo, setCargo] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    // Obtener los detalles de la empresa según el ID
    axios
      .get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setCompanyDetails(response.data.empresa);
      })
      .catch((error) => console.error('Error fetching company details:', error));
  }, [id, jwtToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      id: id, // Utilizar el ID obtenido desde los parámetros
      users: [
        {
          name: username,
          email: email,
          user_cargo: cargo,
          password: password,
        },
      ],
    };

    try {
      await axios.put(
        `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/users`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      navigate('/perfiladmin');
    } catch (error) {
      console.error('Error adding user:', error);
      setErrorMessage(error.response?.data?.error || 'Error al agregar el usuario.');
    }
  };

  return (
    <div className="WT1">
      <Header />

      <div className="containerMatch1">
        <p className="boldMiPerfil">Agregar Usuario Empresa</p>


        <form onSubmit={handleSubmit} className="formAgregarUsuario">
          <div className="subcontainerAgregar">
            <label className="normalEliminar">Nombre del Usuario:</label>
            <input
              type="text"
              className="inputEliminar"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Ingresa el nombre del usuario"
            />
          </div>

          <div className="subcontainerAgregar">
            <label className="normalEliminar">Email:</label>
            <input
              type="email"
              className="inputEliminar"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingresa el email del usuario"
            />
          </div>

          <div className="subcontainerAgregar">
            <label className="normalEliminar">Cargo:</label>
            <input
              type="text"
              className="inputEliminar"
              value={cargo}
              onChange={(e) => setCargo(e.target.value)}
              placeholder="Ingresa el cargo del usuario"
            />
          </div>

          <div className="subcontainerAgregar">
            <label className="normalEliminar">Contraseña:</label>
            <input
              type="password"
              className="inputEliminar"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingresa la contraseña"
            />
          </div>

          <button type="submit" id="enviarAgregar">
            Agregar Usuario
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </div>

      <img src={wot24} alt="circulo" className="wot24Empresas" />
      <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="lista" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="lista" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="active" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="lista" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="lista" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="lista" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default AgregarUsuario;