import '../empresas.css';
import './admin.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header';



const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}


const Usuarias = () => {
    const [usuarias, setUsuarias] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const jwtToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

    // Debouncing para evitar muchas solicitudes al escribir
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 300); // 300ms de retraso

        return () => clearTimeout(handler);
    }, [searchTerm]);

    useEffect(() => {
        fetchData();
    }, [debouncedSearchTerm, page]); // Actualiza cuando cambia el término o la página

    const fetchData = async () => {
        setLoading(true);
        setError(null);

        const endpoint = debouncedSearchTerm
            ? `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/filter/${debouncedSearchTerm}/${page}`
            : `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/usuarias/page/${page}`;

        try {
            const response = await axios.get(endpoint, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setUsuarias(response.data);
        } catch (err) {
            setError(err.message || 'Ha ocurrido un error al obtener los datos.');
        } finally {
            setLoading(false);
        }
    };

    const handleVerDetalle = (id) => {
        navigate(`/detalleUsuaria/${id}`);
    };

    const handleCambioContrasena = (email) => {
        navigate(`/cambioContrasena?email=${email}`);
    };

    return (
        <div className="WT1">
            <Header />
            <div className="buscador-container">
                <input
                    className="busqueda"
                    type="text"
                    placeholder="Buscar por RUT, Nombre o Apellido"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <div className="containerempresas">
                <div className="textoLogin-admin">Usuarias WoT</div>
                {loading ? (
                    <p>Buscando...</p>
                ) : error ? (
                    <p className="error-message">{error}</p>
                ) : usuarias.length > 0 ? (
                    <table className="tabla-usuarias">
                        <thead>
                            <tr>
                                <th>RUT</th>
                                <th>Nombre</th>
                                <th>Apellido</th>
                                <th>Ver Detalle</th>
                                <th>Cambiar Contraseña</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usuarias.map((usuaria) => (
                                <tr key={usuaria.id}>
                                    <td>{usuaria.rut}</td>
                                    <td>{usuaria.nombre}</td>
                                    <td>{usuaria.apellido}</td>
                                    <td>
                                        <button onClick={() => handleVerDetalle(usuaria.id)}>
                                            Ver Detalle
                                        </button>
                                    </td>
                                    <td>
                                        <button onClick={() => handleCambioContrasena(usuaria.mail)}>
                                            Cambiar Contraseña
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No se encontraron usuarias.</p>
                )}
                <div className="paginacion">
                    {page > 1 && (
                        <button onClick={() => setPage((prev) => prev - 1)} className="boton-menos">
                            Ver menos
                        </button>
                    )}
                    <button onClick={() => setPage((prev) => prev + 1)} className="boton-mas">
                        Ver más
                    </button>
                </div>
            </div>
            <div className="rectangulo"></div>
            <img src={wot24} alt="circulo" className="wot24" />
        </div>
    );
};

export default Usuarias;