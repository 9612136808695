import '../empresas.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import wot24 from '../iconos/wot24.png';
import axios from 'axios';
import Header from '../componentes/Header';

const UsuarioItem = ({ user, handleEliminarUsuario }) => {
  return (
    <div className="subcontainerUsuario">
      <h3>{user.name}</h3>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Cargo:</strong> {user.cargo || "No especificado"}</p>
      <button onClick={() => handleEliminarUsuario(user.email)} className="boton-usuario">Eliminar</button>
    </div>
  );
};

const UsuariosEmpresa = () => {
  const jwtToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { id } = useParams();
  const [company, setCompany] = useState([]);
  const [users, setUsers] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!jwtToken) {
      navigate('/loginAdmin');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    axios
      .get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/${id}/users`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const data = response.data;

        // Guardar la empresa
        if (data.empresa) {
          setCompany(data.empresa);
        }

        // Guardar usuarios si es un array
        if (Array.isArray(data.users?.data)) {
          const userList = data.users.data.map((user) => ({
            id: user.attributes.id,
            name: user.attributes['user-name'],
            email: user.attributes['user-email'],
            cargo: user.attributes['user-cargo'] || 'No especificado',
          }));
          setUsers(userList); // Guardar los usuarios en el estado
        }
      })
      .catch((error) => {
        console.error('Error al obtener los datos:', error);
      });
  }, [id, jwtToken]);

  const handleEliminarUsuario = async (email) => {
    const payload = {
      id: id, // Utilizar el ID obtenido de los parámetros
      users: [{ email }],
    };

    try {
      await axios.delete(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/users`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      // Actualizar la lista de usuarios después de eliminar
      setUsers(users.filter((user) => user.email !== email));
    } catch (error) {
      console.error('Error al eliminar usuario:', error);
      setErrorMessage(error.response?.data?.error || 'Error al eliminar el usuario.');
    }
  };

  const handleAgregarUsuario = () => {
    navigate(`/agregarUsuario/${id}`);
  };

  return (
    <div className="WT1">
      <Header />

      <div className="containerMatch1">
        <h2 className="boldMiPerfil">Usuarios de la Empresa</h2>
        {users.length > 0 ? (
          users.map((user) => (
            <UsuarioItem key={user.id} user={user} handleEliminarUsuario={handleEliminarUsuario} />
          ))
        ) : (
          <p className="normalMiPerfil">No hay usuarios disponibles.</p>
        )}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button onClick={handleAgregarUsuario} className="boton-agregar-usuario">Agregar Usuario</button>
      </div>

      <img src={wot24} alt="circulo" className="wot24Empresas" />

      <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="lista" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="active" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="lista" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="lista" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="lista" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="lista" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default UsuariosEmpresa;