import '../empresas.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../componentes/Header';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';

const EliminarUsuario = () => {
  const jwtToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [companyDetails, setCompanyDetails] = useState(null);
  const { id } = useParams(); // Obtener el ID de la empresa de los parámetros

  useEffect(() => {
    // Obtener detalles de la empresa directamente con el ID de los parámetros
    axios
      .get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/${id}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setCompanyDetails(response.data.empresa);
      })
      .catch((error) => console.error('Error fetching company details:', error));
  }, [id, jwtToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      id: id, // Usar directamente el ID de los parámetros
      users: [{ email }],
    };

    try {
      await axios.delete(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/users`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json',
        },
        data: payload,
      });
      navigate('/perfiladmin');
    } catch (error) {
      console.error('Error deleting user:', error);
      setErrorMessage(error.response?.data?.error || 'Error al eliminar el usuario.');
    }
  };

  return (
    <div className="WT1">
      <Header />

      <div className="containerMatch1">
        <p className="boldMiPerfil">Eliminar Usuario Empresa</p>


        <form onSubmit={handleSubmit} className="formEliminarUsuario">
          <div className="subcontainerEliminar">
            <label className="normalEliminar">Email del Usuario a Eliminar:</label>
            <input
              type="email"
              className="inputEliminar"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="mail a eliminar"
            />
          </div>

          <button type="submit" id="enviar">
            Eliminar Usuario
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </div>

      <img src={wot24} alt="circulo" className="wot24Empresas" />
      <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="lista" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="lista" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="lista" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="active" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="lista" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="lista" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
      <img src={wot24} alt="circulo" className="wot24Empresas" />
    </div>
  );
};

export default EliminarUsuario;