import '../empresas.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header';




const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}


const MiPerfil = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const { id } = useParams();
    const [company, setCompany] = useState([]);

    useEffect(() => {
        if (!jwtToken) {
          navigate('/loginAdmin');
        } 
      }, [jwtToken, navigate]);



    const handleActiveDeactiveCompany = useCallback(async () => {
        try {
          await axios.put(
            `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/active-deactive`,
            {
              active: !company.activo,
              id: company.id,
            },
            {
              headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
    
          // Actualizar el estado para reflejar el cambio de activo 
          setCompany((prevCompany) => ({ ...prevCompany, activo: !prevCompany.activo }));
        } catch (error) {
          console.error(error);
        }
      }, [company.id, company.activo, jwtToken]);


    useEffect(() => {
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/${id}`, {
            headers:{
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const data = response.data;
            if (Array.isArray(data)) {
                console.log(data);
                setCompany(data.empresa);
            }
            else if (typeof data === 'object') {
                console.log(data.empresa);
                setCompany(data.empresa);
            }
        })
        .catch(error => {console.error(error);
        });
    }, []);
  
    return (
    <div className="WT1">

        <Header />  

        <div className='containerMatch1'>
         <p className='boldMiPerfil'> {company.nombre}</p>
            <h1>{company.activo ? 'Activa' : 'Desactivada'}</h1>

         <div class="subcontainerMatchimg">
            <img src={wot25} className='subcontainerMatchimg'/>
        </div>
         <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Rut</h>
            <p className='normalMiPerfil'>{company.rut}</p>
        </div>
        <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Valores Empresa</h>
            <p className='normalMiPerfil'>{company.valoresEmpresa}</p>
        </div>
        <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Industria</h>
        {company.industria ? (
            <p className='normalMiPerfil'>{company.industria.nombre_industria}</p>) : 
            (<p className = 'normalMiPerfil'>Cargando...</p>)}
        </div>
        <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Ingreso Anual</h>
            {company.ingresoAnual ? (
            <p className='normalMiPerfil'>{company.ingresoAnual.rango_ingresos_desde}UF -
            {company.ingresoAnual.rango_ingresos_hasta}UF </p>) : 
            (<p className = 'normalMiPerfil'>Cargando...</p>)}
           
        </div>
        <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Fundacion</h>
            {company.fundacion ? (
            <p className='normalMiPerfil'>{company.fundacion.rango_anios_desde} -
            {company.fundacion.rango_anios_hasta} </p>) : 
            (<p className = 'normalMiPerfil'>Cargando...</p>)}
           
        </div>
        <div class="subcontainerMatch">
            <h className='normalMiPerfil'>Región</h>
            {company.region ? (
            <p className='normalMiPerfil'>{company.region.nombre}</p>) : 
            (<p className = 'normalMiPerfil'>Cargando...</p>)}
        </div>
        <a id='busqueda' onClick={handleActiveDeactiveCompany}>
            {company.activo ? 'Desactivar' : 'Activar'}
        </a>

        </div>

        <img src={wot24} alt='circulo' className='wot24Empresas' />
        
        
        <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="active" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="lista" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="lista" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="lista" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="lista" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="lista" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
        <img src={wot24} alt='circulo' className='wot24Empresas' />
    </div>
    )
}

export default MiPerfil