import '../empresas.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import wot25 from '../iconos/wot25.png';
import axios from 'axios';
import Header from '../componentes/Header';



const logout = () => {
    localStorage.removeItem('accessToken');
    window.location.reload();
}

const BusquedaItem = ({ search }) => {

    const navigate = useNavigate();
  
    const handleVerDetalle = (id) => {
      console.log(id)
      navigate(`/matchs-empresa/${id}`);
    };
  
    const formattedDate = new Date(search.busquedaFecha ).toLocaleString();
    return (
      <div className="subcontainerUsuario">
        <h>{search.busquedaNombre}</h>
        <p>Fecha: {formattedDate}</p>
        <button onClick={() => handleVerDetalle(search.busquedaId)} className='boton-bus'>Ver Detalle</button>
      </div>
    );
  };

const BusquedasEmpresa = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const { id } = useParams();
    const [company, setCompany] = useState([]);
    const [allSearches, setAllSearches] = useState([]);

    useEffect(() => {
        if (!jwtToken) {
          navigate('/loginAdmin');
        } 
      }, [jwtToken, navigate]);

   

    useEffect(() => {
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/company/${id}`, {
            headers:{
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            const data = response.data;
            if (Array.isArray(data)) {
                setCompany(data.empresa);
            }
            else if (typeof data === 'object') {
                setCompany(data.empresa);
            }
        })
        .catch(error => {console.error(error);
        });
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/empresa/${id}`, {
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (Array.isArray(response.data)) {
                setAllSearches(response.data);
            } else {
                setAllSearches([]);
            }
        })
        .catch(error => console.error('Error:', error));

    }, [id, jwtToken]);
  
    return (
    <div className="WT1">

        <Header />  

        <div className='containerMatch1'>
            <p className='boldMiPerfil'>Busquedas Realizadas</p>
            {allSearches.length > 0 ? (
              allSearches.map((search, index) => (<BusquedaItem key={index} search={search} />
              ))
            ) : (
              <p>No hay búsquedas disponibles.</p>
            )}

        </div>

        <img src={wot24} alt='circulo' className='wot24Empresas' />
        
        
        <div className="rectanguloEmpresas">
        <p className="boldMiPerfil">Acciones Para las Empresas</p>
        <a id="lista" onClick={() => navigate(`/empresa/${id}`)}>Activar / Desactivar Empresa</a>
        <a id="lista" onClick={() => navigate(`/usuariosEmpresa/${id}`)}>Ver Usuarios</a>
        <a id="lista" onClick={() => navigate(`/agregarUsuario/${id}`)}>Agregar Usuario</a>
        <a id="lista" onClick={() => navigate(`/eliminarUsuario/${id}`)}>Eliminar Usuario</a>
        <a id="active" onClick={() => navigate(`/busquedasEmpresa/${id}`)}>Busquedas Realizadas</a>
        <a id="lista" onClick={() => navigate(`/desbloqueadosEmpresa/${id}`)}>Aperturas</a>
      </div>
        <img src={wot24} alt='circulo' className='wot24Empresas' />
    </div>
    )
}

export default BusquedasEmpresa