import '../empresas.css';
import wot20 from '../iconos/wot20.png';
import wot24 from '../iconos/wot24.png';
import {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import axios from 'axios';
import Header from '../componentes/Header';


const CambioContrasena = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation(); // Hook para obtener los query params
    const jwtToken = localStorage.getItem('accessToken');
    const [newPassword, setNewPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');

    // Obtener el email desde los query params
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailFromQuery = queryParams.get('email');
        if (emailFromQuery) {
            setEmail(emailFromQuery); // Establece el email automáticamente si está presente en los query params
            obtenerId(emailFromQuery);
        }
    }, [location]);

    const obtenerId = (email) => {
        axios
            .get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/users/user-by-mail/${email}`, {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            })
            .then((response) => {
                setUsername(response.data.id);
            })
            .catch((error) => {
                console.error('Error al obtener el ID del usuario:', error);
                setErrorMessage('No se pudo obtener el ID del usuario.');
            });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        axios
            .post(
                `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/admin/users/change-password`,
                { username: username, newPassword: newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(() => {
                navigate('/perfiladmin');
            })
            .catch((error) => {
                console.error('Error al cambiar la contraseña:', error);
                setErrorMessage(error.response?.data?.error || 'Ha ocurrido un error.');
            });
    };

    return (
        <div className="WT1">
            <Header />
            <div className="containerempresas">
                <form onSubmit={handleSubmit}>
                    <div className="texto-admin">Cambio de contraseña para una usuaria</div>
                    <div className="nombreUsuario">
                        <label>Email</label>
                        <div></div>
                        <input
                            className="input1"
                            name="email"
                            type="text"
                            value={email} // Email prellenado
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="nuevaContrasena">
                        <label>Nueva Contraseña</label>
                        <div></div>
                        <input
                            className="input1"
                            name="newPassword"
                            type="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" id="cambioContrasena">
                        Enviar
                    </button>
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                </form>
            </div>
            <div className="rectangulo"></div>
            <img src={wot24} alt="circulo" className="wot24" />
        </div>
    );
};

export default CambioContrasena;